import React from "react";
import "../assets/styles/Services.css";

const Services = () => {
  return (
    <section id="services" className="container mt-5">
      <div className="row">
        <span className="servicesMainHeading">
          Appwaysys - Mobile and Web App Development
        </span>
      </div>
      <div className="row">
        <span className="servicesSubHeading">Services</span>
      </div>
      <br></br>
      <div className="row">
        <div className="col-md-4">
          <div className="row">
            <span className="servicesHeading">Mobile App Development</span>
          </div>
          <div className="row">
            <span className="serviceDescription">
              We specialize in developing high-quality mobile applications for
              iOS and Android platforms.
            </span>
          </div>
          <p className="serviceTagsRow">
            <span className="serviceTags">User-friendly interface</span>
            <span className="serviceTags">Cross-platform compatibility</span>
            <span className="serviceTags">
              Seamless integration with backend systems
            </span>
          </p>
        </div>
        <div className="col-md-4">
          <div className="row">
            <span className="servicesHeading">Web App Development</span>
          </div>
          <div className="row">
            <span className="serviceDescription">
              We offer custom web application development services using the
              latest technologies and frameworks.
            </span>
          </div>
          <p className="serviceTagsRow">
            <span className="serviceTags">Responsive design</span>
            <span className="serviceTags">Scalable architecture</span>
            <span className="serviceTags">Secure data storage</span>
          </p>
        </div>
        <div className="col-md-4">
          <div className="row">
            <span className="servicesHeading">UI/UX Design</span>
          </div>
          <div className="row">
            <span className="serviceDescription">
              Our team of designers creates visually appealing and intuitive
              user interfaces for mobile and web applications.
            </span>
          </div>
          <p className="serviceTagsRow">
            <span className="serviceTags">Wireframing and prototyping</span>
            <span className="serviceTags">Usability testing</span>
            <span className="serviceTags">Brand identity integration</span>
          </p>
        </div>
      </div>
      <div className="row">
        <div className="col-md-4">
          <div className="row">
            <span className="servicesHeading">Backend Development</span>
          </div>
          <div className="row">
            <span className="serviceDescription">
              We provide robust and scalable backend development solutions to
              support your mobile and web applications.
            </span>
          </div>
          <p className="serviceTagsRow">
            <span className="serviceTags">Database management</span>
            <span className="serviceTags">API integration</span>
            <span className="serviceTags">Server-side scripting</span>
          </p>
        </div>
        <div className="col-md-4">
          <div className="row">
            <span className="servicesHeading">Quality Assurance</span>
          </div>
          <div className="row">
            <span className="serviceDescription">
              We ensure the quality and reliability of your applications through
              comprehensive testing and quality assurance processes.
            </span>
          </div>
          <p className="serviceTagsRow">
            <span className="serviceTags">Functional testing</span>
            <span className="serviceTags">Performance testing</span>
            <span className="serviceTags">Security testing</span>
          </p>
        </div>
        <div className="col-md-4">
          <div className="row">
            <span className="servicesHeading">Maintenance and Support</span>
          </div>
          <div className="row">
            <span className="serviceDescription">
              We offer ongoing maintenance and support services to ensure the
              smooth operation of your applications.
            </span>
          </div>
          <p className="serviceTagsRow">
            <span className="serviceTags">Bug fixing</span>
            <span className="serviceTags">Feature enhancements</span>
            <span className="serviceTags">24/7 technical support</span>
          </p>
        </div>
      </div>

      {/* Add more content */}
    </section>
  );
};

export default Services;
