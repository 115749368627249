import React from "react";
import "../assets/styles/Footer.css";
import { FaFacebookSquare, FaWhatsapp, FaInstagram } from "react-icons/fa";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <p className="companyFooterDetails">
              <span className="footerCompanyName">Appwaysys</span>
              <br />
              {/* <span className="footerCompanyLocation">Kerala</span> */}
            </p>
          </div>
          <div className="col-md-6">
            <p className="companySocialDetails">
              <span className="followOn">Follow us on</span>
              <br />
              <span className="footerCompanySocialMedias">
                <span
                  id="list"
                  onClick={(e) =>
                    window.open("https://www.instagram.com/appwaysys", "_blank")
                  }
                >
                  <FaInstagram />
                </span>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <span
                  id="list"
                  onClick={(e) =>
                    window.open(
                      "https://www.facebook.com/profile.php?id=61563930976376",
                      "_blank"
                    )
                  }
                >
                  <FaFacebookSquare />
                </span>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <span
                  id="list"
                  onClick={(e) =>
                    window.open("https://wa.me/+919980462591", "_blank")
                  }
                >
                  <FaWhatsapp />
                </span>
              </span>
            </p>
          </div>
        </div>
        <br />
        <div className="row">
          <p className="copyright">
            &copy; {new Date().getFullYear()} Appwaysys. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
