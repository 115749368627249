import React from "react";
import { scrollInNavbar } from "../pages/Home";
import "../assets/styles/Header.css";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";

const Header = () => {
  return (
    <>
      <Navbar
        bg="myColor"
        data-bs-theme="dark"
        collapseOnSelect
        expand="lg"
        className="bg-body-tertiary"
      >
        <Container>
          <Navbar.Brand className="companyName" href="/">
            Appwaysys
          </Navbar.Brand>

          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="justify-content-end flex-grow-1 pe-3">
              <Nav.Link href="/">Home</Nav.Link>
              <Nav.Link onClick={() => scrollInNavbar("#features")}>
                Features
              </Nav.Link>
              <Nav.Link onClick={() => scrollInNavbar("#services")}>
                Services
              </Nav.Link>
              <Nav.Link onClick={() => scrollInNavbar("#aboutUs")}>
                About Us
              </Nav.Link>
              <Nav.Link onClick={() => scrollInNavbar("#contactUs")}>
                Contact
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};

export default Header;
