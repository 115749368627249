import React from "react";
import Features from "../components/Features";
import Services from "../components/Services";
import ContactForm from "../components/ContactForm";
import AboutUs from "../components/AboutUs";
import "bootstrap/dist/css/bootstrap.min.css";
import "../assets/styles/Home.css";

const Home = () => {
  return (
    <div className="App">
      <header className="homeContainer text-white text-center p-5">
        <div className="container headingContainer">
          <div className="row">
            <h1 className="mainHeading">Welcome to Appwaysys</h1>
            <br /> <br />
            <h2 className="subHeading">
              Your Partner in Mobile and Web App Development
            </h2>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <img
              className="homePageImage"
              src="./images/homePageImage.jpg"
              alt="homepageImage"
              loading="lazy"
            ></img>
          </div>
        </div>
      </header>
      <Features />
      <Services />
      <AboutUs />
      <ContactForm />
    </div>
  );
};

export default Home;

export const scrollInNavbar = (selector) => {
  const section = document.querySelector(selector);
  section.scrollIntoView({ behavior: "smooth", block: "start" });
};
