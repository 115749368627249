import React from "react";
import "../assets/styles/AboutUs.css";

const AboutUs = () => {
  return (
    <section id="aboutUs" className="container mt-5">
      <div className="row">
        <span className="aboutUsMainHeading">AboutUs</span>
      </div>

      <div className="row">
        <p className="aboutUsDescriptionBlock">
          <span className="aboutUsDescription">
            Appwaysys is a leading service business based in Kerala,
            specializing in mobile and web app development. Our mission is to
            empower businesses and individuals with innovative and cutting-edge
            technology solutions. We strive to deliver high-quality apps that
            enhance user experiences and drive business growth.
          </span>
          <br />
          <span className="aboutUsDescription">
            At Appwaysys, our vision is to become the go-to partner for
            businesses seeking reliable and scalable app development services.
            We aim to be recognized as industry leaders in delivering customized
            solutions that meet the unique needs and requirements of our
            clients.
          </span>
          <br />
          <span className="aboutUsDescription">
            Our goals are centered around customer satisfaction and success. We
            are committed to providing exceptional customer service, ensuring
            that our clients receive the support and guidance they need
            throughout the development process. We also strive to stay at the
            forefront of technological advancements, constantly improving our
            skills and expertise to deliver the most up-to-date solutions.
          </span>
          <br />
          <span className="aboutUsDescription">
            With a team of highly skilled professionals, Appwaysys is dedicated
            to exceeding client expectations and creating apps that make a
            difference. Contact us today to discuss your app development needs
            and let us help you bring your ideas to life.
          </span>
        </p>
      </div>
    </section>
  );
};

export default AboutUs;
