import React from "react";
import "../assets/styles/Features.css";

const Features = () => {
  return (
    <section id="features" className="container mt-5">
      <div className="row">
        <span className="servicesMainHeading">Our Services</span>
      </div>
      <div className="row">
        <span className="featuresMainHeading">Features</span>
      </div>
      <br></br>
      <div className="row">
        <div className="col-md-4">
          <img
            className="featuresImage"
            src="./images/ios.jpg"
            alt="flutter"
            loading="lazy"
          ></img>
          <br></br>
          <div className="row">
            <span className="featuresHeading">Mobile App Development</span>
          </div>
          <div className="row">
            <p className="featuresDescription">
              We offer professional mobile app development services for iOS and
              Android platforms. Our team of experienced developers will create
              innovative and user-friendly mobile apps tailored to your specific
              business needs.
            </p>
          </div>
        </div>
        <div className="col-md-4">
          <img
            className="featuresImage"
            src="./images/webDevelopment.jpg"
            alt="web"
            loading="lazy"
          ></img>
          <br></br>
          <div className="row">
            <span className="featuresHeading">Web App Development</span>
          </div>
          <div className="row">
            <p className="featuresDescription">
              With our expertise in web app development, we can build scalable
              and secure web applications using cutting-edge technologies. From
              e-commerce platforms to enterprise solutions, we deliver
              high-quality web apps that drive business growth.
            </p>
          </div>
        </div>
        <div className="col-md-4">
          <img
            className="featuresImage"
            src="./images/android.jpg"
            alt="android"
            loading="lazy"
          ></img>
          <br></br>
          <div className="row">
            <span className="featuresHeading">Custom App Development</span>
          </div>
          <div className="row">
            <p className="featuresDescription">
              At Appwaysys, we specialize in custom app development to meet the
              unique requirements of your business. Our team will work closely
              with you to understand your goals and deliver a tailor-made app
              that enhances your brand and improves user experience.
            </p>
          </div>
        </div>
      </div>

      {/* Add more content */}
    </section>
  );
};

export default Features;
