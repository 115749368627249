import React, { useState } from "react";
import emailjs from "@emailjs/browser";
import { CiLocationOn } from "react-icons/ci";
import "../assets/styles/ContactForm.css";

const ContactForm = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [stateMessage, setStateMessage] = useState(null);

  const sendEmail = (e) => {
    e.persist();
    e.preventDefault();
    setIsSubmitting(true);
    emailjs
      .sendForm(
        process.env.REACT_APP_EMAIL_SERVICE_ID,
        process.env.REACT_APP_EMAIL_TEMPLATE_ID,
        e.target,
        process.env.REACT_APP_EMAIL_PUBLIC_KEY
      )
      .then(
        (result) => {
          setStateMessage("Message sent!");
          setIsSubmitting(false);
          setTimeout(() => {
            setStateMessage(null);
          }, 5000); // hide message after 5 seconds
        },
        (error) => {
          console.log(error);
          setStateMessage("Something went wrong, please try again later");
          setIsSubmitting(false);
          setTimeout(() => {
            setStateMessage(null);
          }, 5000); // hide message after 5 seconds
        }
      );

    e.target.reset();
  };

  return (
    <section id="contactUs" className="container mt-5">
      <div className="row">
        <div className="col-md-6">
          <p className="contactFormTexts">
            <span className="getInTouch">Get in touch with Appwaysys</span>
            <br></br>
            <span className="contactUs">Contact Us</span>
            <br></br>
            <CiLocationOn />
            <span className="location">Kerala, India</span>
          </p>
        </div>
        <div className="col-md-6 align-self-center">
          <form className="px-4 py-3" onSubmit={sendEmail}>
            <div className="mb-3">
              <label className="form-label contactLabel">Name</label>
              <input
                type="text"
                className="form-control"
                required
                name="name"
                size={1}
              />
            </div>
            <div className="mb-3">
              <label className="form-label contactLabel">Email</label>
              <input
                type="email"
                className="form-control"
                name="email"
                required
              />
            </div>
            <div className="mb-3">
              <label className="form-label contactLabel">Message</label>
              <textarea
                name="message"
                className="form-control"
                rows={3}
                required
              />
            </div>
            <div className="mb-3 submitButtonDiv">
              <button
                type="submit"
                className="submitButton"
                disabled={isSubmitting}
                load
              >
                {/* <i className="fa fa-refresh fa-spin"></i> */}
                Submit
              </button>
            </div>
            {stateMessage && <p>{stateMessage}</p>}
          </form>
        </div>
      </div>

      {/* <h2>Contact Us</h2>
      <p>Let's discuss how we can help your business thrive.</p> */}
    </section>
  );
};

export default ContactForm;
